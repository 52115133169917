<template>
    <a-card>
        <a-page-header
            :title='"自动规则执行记录"'
        />
        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row :gutter="16">
                <a-col :span="8">
                    <a-form-model-item label="执行时间">
                        <a-range-picker
                            v-model="pickerTime"
                            value-format="YYYY-MM-DD"
                            :disabled-date="disabledDate"
                            :allow-clear="false"
                            @calendarChange="calendarChange"
                            @openChange="openChange"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="规则类型">
                        <a-select v-model="form.ruleType" placeholder="请选择" show-search allowClear>
                            <a-select-option v-for="item in rulesTypeList" :key="item.code" :value="item.code">{{ item.msg
                            }}</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="8">
                    <a-form-model-item label="自动规则ID">
                        <a-input
                            v-model="form.ruleId"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row type="flex" justify="end">
                <a-button @click="handleReset">重置</a-button>
                <a-button style="margin: 0 5px 0 20px;" type="primary" @click="handleSearch">查询</a-button>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => index"
            :pagination='false'
            style="margin-top: 30px;"
        >
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />
    </a-card>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {},
            pickerTime: [moment().subtract(30, 'days'), moment()],
            selectStartDay: '',
            rulesTypeList: [],
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '执行时间',
                    dataIndex: 'ctime',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '规则类型',
                    dataIndex: 'ruleTypeName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '规则层级',
                    dataIndex: 'applyObjectTypeName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '执行',
                    dataIndex: 'actionTypeName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '通知内容',
                    dataIndex: 'actionNotice',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '自动操作内容',
                    dataIndex: 'actionDesc',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '自动规则ID',
                    dataIndex: 'ruleId',
                    customRender(text) {
                        return text || '-'
                    }
                },
            ],
            pagination: {
                current: 1,
                pageSize: 50,
                total: 0
            },
            dataList: [],
        }
    },
    created() {
        this.getAdRuleTypeList()
        this.getDataList()
    },
    methods: {
        disabledDate(current) {
            return current > this.$moment(this.selectStartDay).add(1000, 'days') || current < this.$moment(this.selectStartDay).subtract(1000, 'days')
        },
        calendarChange(dates, dateStrings) {
            console.log(dates, dateStrings)
            this.selectStartDay = dates[0]
        },
        openChange(status) {
            this.selectStartDay = ''
        },
        //获取规则类型数据
        getAdRuleTypeList() {
            this.$api.core.deliverManage.getAdRuleTypeList().then((res) => {
                if(res.code == 200) {
                    this.rulesTypeList = res.data
                } else {
                    this.$message.error(`获取规则数据失败，${res.message}`)
                }
            })
        },
        handleReset() {
            this.form = {}
            this.pickerTime = [moment().subtract(30, 'days'), moment()]
            this.selectStartDay = ''
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList() {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                startTime: moment(this.pickerTime[0]).format('YYYY-MM-DD 00:00:00'),
                endTime: moment(this.pickerTime[1]).format('YYYY-MM-DD 23:59:59'),
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.deliverManage.getAdAutomateRuleActionLog(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    console.error(`获取列表失败${res}`)
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.mt-20 {
    margin-top: 20px;
}
</style>